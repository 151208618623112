import React from 'react'
import './style.scss'

function MRUTuitionModal() {


  return (
    <div id="tuition-modal" className="grid-12 c5 padd padd-top-2 padd-bottom-2">

      <div className='intro outline fs-125'>
        <h2>Tuition & Enrollment</h2>
      </div>

      <div className='table container fs-85'>
        <table>
          <thead>
            <tr>
              <th className='title'>Program Title</th>
              <th>Tuition Per Credit HOur</th>
              <th>Credits</th>
              <th>Total Tuition</th>
              <th>General Fees</th>
              <th>InternationaL Student Fee</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Associate of Science in Nursing (ASN)</td>
              <td>$551</td>
              <td>66</td>
              <td>$36,366</td>
              <td>$10,252</td>
              <td>$250</td>
              <td>$46,868</td>
            </tr>
            <tr>
              <td>Bachelor of Science in Nursing (BSN)</td>
              <td>$551</td>
              <td>120</td>
              <td>$66,120</td>
              <td>$3,075</td>
              <td>$250</td>
              <td>$69,445</td>
            </tr>
            <tr>
              <td>Bachelor of Science in Applied Behavior Analysis (BS-ABA)</td>
              <td>$551</td>
              <td>123</td>
              <td>$67,773</td>
              <td>$5,435</td>
              <td>$250</td>
              <td>$73,458</td>
            </tr>
            <tr>
              <td>Master of Business Administration (MBA)</td>
              <td>$600</td>
              <td>36</td>
              <td>$21,600</td>
              <td>$1,220</td>
              <td>$250</td>
              <td>$23,070</td>
            </tr>
            <tr>
              <td>Master of Education Administration (Med)</td>
              <td>$600</td>
              <td>36</td>
              <td>$21,600</td>
              <td>$965</td>
              <td>$250</td>
              <td>$22,815</td>
            </tr>
            <tr>
              <td>Master of Science in Nursing Direct Entry (MSN-DE)</td>
              <td>$670</td>
              <td>64</td>
              <td>$42,880</td>
              <td>$11,082</td>
              <td>$250</td>
              <td>$54,212</td>
            </tr>
            <tr>
              <td>Master of Science in Nursing Family Nurse Practioner (FNP)</td>
              <td>$600</td>
              <td>46</td>
              <td>$27,600</td>
              <td>$11,595</td>
              <td>$250</td>
              <td>$39,445</td>
            </tr>
            <tr>
              <td>Family Nurse Practioner Post Master Certificate (FNP-PMC)</td>
              <td>$640</td>
              <td>37</td>
              <td>$23,680</td>
              <td>$11,100</td>
              <td>$250</td>
              <td>$35,030</td>
            </tr>
            <tr>
              <td>Psychiatric Mental Health Post Master Certificate (PMNHP-PMC)</td>
              <td>$640</td>
              <td>35</td>
              <td>$24,320</td>
              <td>$2,130</td>
              <td>$250</td>
              <td>$24,780</td>
            </tr>
            <tr>
              <td>Adult-Gerontology Acute Care Post Master Certificate  (AGACNP)</td>
              <td>$640</td>
              <td>30</td>
              <td>$19,200</td>
              <td>$3,500</td>
              <td>$250</td>
              <td>$22,950</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className='table container fs-85'>
        <table>
          <thead>
            <tr>
              <th className='title'>Program Title</th>
              <th>Housing & Meals</th>
              <th>Travel</th>
              <th>Personal</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
          <tr>
              <td>Associate of Science in Nursing (ASN)</td>
              <td>$14,386</td>
              <td>$2,054</td>
              <td>$1,500</td>
              <td>$17,940</td>
            </tr>
            <tr>
              <td>Bachelor of Science in Nursing (BSN)</td>
              <td>$14,386</td>
              <td>$2,054</td>
              <td>$1,500</td>
              <td>$17,940</td>
            </tr>
            <tr>
              <td>Bachelor of Science in Applied Behavior Analysis (BS-ABA)</td>
              <td>$14,386</td>
              <td>$2,054</td>
              <td>$1,500</td>
              <td>$17,940</td>
            </tr>
            <tr>
              <td>Master of Business Administration (MBA)</td>
              <td>$14,386</td>
              <td>$2,054</td>
              <td>$1,500</td>
              <td>$17,940</td>
            </tr>
            <tr>
              <td>Master of Education Administration (Med)</td>
              {/* <td>N/A <span className='small-message'>Tuition is all-inclusive</span></td> */}
              <td>$14,386</td>
              <td>$2,054</td>
              <td>$1,500</td>
              <td>$17,940</td>
            </tr>
            <tr>
              <td>Master of Science in Nursing Direct Entry (MSN-DE)</td>
              <td>$14,386</td>
              <td>$2,054</td>
              <td>$1,500</td>
              <td>$17,940</td>
            </tr>
            <tr>
              <td>Master of Science in Nursing Family Nurse Practioner (FNP)</td>
              <td>$14,386</td>
              <td>$2,054</td>
              <td>$1,500</td>
              <td>$17,940</td>
            </tr>
            <tr>
              <td>Family Nurse Practioner Post Master Certificate (FNP-PMC)</td>
              <td>$14,386</td>
              <td>$2,054</td>
              <td>$1,500</td>
              <td>$17,940</td>
            </tr>
            <tr>
              <td>Psychiatric Mental Health Post Master Certificate (PMNHP-PMC)</td>
              <td>$14,386</td>
              <td>$2,054</td>
              <td>$1,500</td>
              <td>$17,940</td>
            </tr>
            <tr>
              <td>Adult-Gerontology Acute Care Post Master Certificate  (AGACNP)</td>
              <td>$14,386</td>
              <td>$2,054</td>
              <td>$1,500</td>
              <td>$17,940</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default MRUTuitionModal